import {
  Typography,
  Chip,
  Box,
  Divider,
  Avatar,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  Button as MuiButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import myAxios from "helpers/axios";
import { getLocale } from "layout/MyAppBar";
import React from "react";
import { useNotify, useRefresh } from "react-admin";
import { useFormState, Form, Field } from "react-final-form";

const OrderPanel = ({ id, record, resource }) => {
  const locale = getLocale();
  return (
    <Box style={{ padding: 10 }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          justifyContent: "space-around",
        }}
      >
        <Box>
          {record?.workerInfo ? (
            <Box style={{ display: "flex", flexDirection: "row", gap: 8 }}>
              <Avatar
                alt={record?.workerInfo?.full_name}
                src={record?.workerInfo?.profilePic.data}
              />
              <Typography
                style={{ fontSize: "16px", color: "rgb(108 108 108)" }}
              >
                {" "}
                <strong>{record?.workerInfo?.full_name}</strong> <br />
                <small>員工編號: {record?.workerInfo?.profile?.wid}</small>
              </Typography>
            </Box>
          ) : null}
          {record?.company_statement_date ? (
            <Typography style={{ fontSize: "16px", color: "rgb(108 108 108)" }}>
              完工日期:{" "}
              {record?.company_statement_date
                ? record?.company_statement_date
                : "N/A"}
            </Typography>
          ) : null}
          {record?.checking_report ? (
            <Typography style={{ fontSize: "16px", color: "rgb(108 108 108)" }}>
              檢完報告:{" "}
              {record?.checking_report ? record?.checking_report : "N/A"}
            </Typography>
          ) : null}
          {record?.complete_report ? (
            <Typography style={{ fontSize: "16px", color: "rgb(108 108 108)" }}>
              完工報告:{" "}
              {record?.complete_report ? record?.complete_report : "N/A"}
            </Typography>
          ) : null}

          {/* {record?.workerInfo?.licenses?.map((license) => {
              return (
                <Typography
                  key={`license-${license.worker_id}-${license.id}`}
                  style={{ fontSize: "12px", color: "rgb(108 108 108)" }}
                >
                  {license.type}
                </Typography>
              );
            })} */}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Typography variant="h6">維修項目</Typography>
            {record?.details.map((orderItem, index) => {
              return (
                <>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                      justifyContent: "space-between",
                    }}
                    key={`orderItem-${orderItem.id}`}
                  >
                    <Box>
                      <Typography
                        style={{ fontSize: "16px", color: "rgb(108 108 108)" }}
                      >
                        {index + 1}.
                        {locale === "zh-TW"
                          ? orderItem.siteServiceItemName
                          : orderItem.siteServiceItemNameEn}
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", color: "rgb(108 108 108)" }}
                      >
                        數量: {orderItem.qty}
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", color: "rgb(108 108 108)" }}
                      >
                        收費: HKD {orderItem.price} * {orderItem.qty} = HKD
                        {orderItem.status == "WAIVED"
                          ? " 0(省免)"
                          : " " + orderItem.qty * orderItem.price}
                      </Typography>
                    </Box>
                    <Box>
                      {orderItem?.resultPics?.map((resultPic) => {
                        return (
                          <Box
                            style={{
                              flexBasis: "30%",
                              maxWidth: "30%",
                              maxHeight: "100px",
                              overflow: "hidden",
                              aspectRatio: "1/1",
                            }}
                          >
                            <a href={resultPic.staticServePath} target="_blank">
                              <img
                                src={resultPic.staticServePath}
                                style={{ width: "100%", maxWidth: 200 }}
                              />
                            </a>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  <Divider
                    style={{
                      backgroundColor: "#eee",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  />
                </>
              );
            })}
          </Box>
          <Box id="cancel-order">
            <CancelOrderDialog order={record} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CancelOrderDialog = ({ order }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const refresh = useRefresh();
  const notify = useNotify();
  const handleSubmit = async (value) => {
    myAxios
      .post(myAxios.addApiUrl(`/client/order/cancel?id=${order.id}`), value)
      .then((res) => {
        handleClose();
        refresh();
      })
      .catch((e) => {
        notify(e.message);
      });
  };
  if (order.status !== "pending_pick_up" && order.status !== "picked_up") {
    return null;
  }
  return (
    <React.Fragment>
      <MuiButton
        variant="contained"
        color="error"
        style={{
          color: "#fff",
          backgroundColor: "#d32f2f",
        }}
        onClick={() => setOpen(true)}
      >
        取消訂單
      </MuiButton>
      <MuiDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MuiDialogTitle id="alert-dialog-title">
          {"確定取消訂單?"}
        </MuiDialogTitle>
        <MuiDialogContent>
          <MuiDialogContentText id="alert-dialog-description">
            <Typography style={{ marginBottom: 10 }}>
              訂單編號: {order.ref}將會被取消。
            </Typography>
            <Form
              onSubmit={handleSubmit}
              keepDirtyOnReinitialize
              subscription={{
                submitting: true,
                pristine: true,
                valid: true,
                invalid: true,
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Field name="remark">
                    {(props) => (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          取消原因
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={props.input.value}
                          label="取消原因"
                          required={true}
                          onChange={props.input.onChange}
                        >
                          <MenuItem value={"分店問題已解決"}>
                            分店問題已解決
                          </MenuItem>
                          <MenuItem value={"未能配對到師傅"}>
                            未能配對到師傅
                          </MenuItem>
                          <MenuItem value={"我選擇了錯的維修項目"}>
                            我選擇了錯的維修項目
                          </MenuItem>
                          <MenuItem value={"我選擇了錯的維修項目"}>
                            錯誤下單
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      gap: 4,
                      justifyContent: "flex-end",
                    }}
                  >
                    <MuiButton
                      onClick={handleClose}
                      variant="outlined"
                      style={{ color: "#999" }}
                    >
                      返回
                    </MuiButton>
                    <MuiButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting || pristine}
                      style={{
                        color: "#fff",
                        backgroundColor: "#d32f2f",
                      }}
                    >
                      確定取消
                    </MuiButton>
                  </div>
                </form>
              )}
            />
          </MuiDialogContentText>
        </MuiDialogContent>
        {/* <MuiDialogActions>
          <MuiButton
            onClick={handleClose}
            variant="outlined"
            style={{ color: "#999" }}
          >
            返回
          </MuiButton>
          <MuiButton
            onClick={handleClose}
            variant="contained"
            style={{
              color: "#fff",
              backgroundColor: "#d32f2f",
            }}
            form="cancel-form"
            type="submit"
            autoFocus
          >
            確定取消
          </MuiButton>
        </MuiDialogActions> */}
      </MuiDialog>
    </React.Fragment>
  );
};

export default OrderPanel;
