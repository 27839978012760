import React from "react";
import {
  ReferenceField,
  useShowController,
  TextField,
  Show,
  useNotify,
  useRefresh,
  TabbedShowLayout,
  Tab,
  CardActions,
  ListButton,
  RefreshButton,
  useTranslate,
} from "react-admin";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import QRCode from "react-qr-code";
import { Button } from "@material-ui/core";
import myAxios from "helpers/axios";

const SiteShowActions = ({ basePath, data }) => {
  const translate = useTranslate();
  return (
    <CardActions>
      <ListButton
        basePath={`/client/companies/${data?.company_id}/show`}
        label={translate("ra.site.list")}
      />
      <RefreshButton label={translate("ra.action.refresh")} />
    </CardActions>
  );
};

const SiteShowAside = ({ record }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const generateArriveAction = React.useCallback(async () => {
    await myAxios.post(
      myAxios.addApiUrl(
        `/client/site/generate-arrive-token?siteId=${record.id}`
      )
    );
    refresh();
    notify(translate("ra.notification.arrival_qr_generated"));
  }, []);
  const generateCompleteAction = React.useCallback(async () => {
    await myAxios.post(
      myAxios.addApiUrl(
        `/client/site/generate-complete-token?siteId=${record.id}`
      )
    );
    refresh();
    notify(translate("ra.notification.complete_qr_generated"));
  }, []);
  const generateConfirmAction = React.useCallback(async () => {
    await myAxios.post(
      myAxios.addApiUrl(
        `/client/site/generate-confirm-token?siteId=${record.id}`
      )
    );
    refresh();
    notify(translate("ra.notification.confirm_qr_generated"));
  }, []);
  return (
    <div style={{ width: "auto", margin: "1em", marginTop: 0 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {translate("ra.site.arrived_qr")}{" "}
            <span style={{ fontWeight: 700 }}>[{record?.arrive_token}]</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ position: "absolute" }}>
            <Button onClick={generateArriveAction} variant="contained">
              {translate("ra.site.generate_qr")}
            </Button>
          </div>
          <div style={{ background: "white", padding: "64px" }}>
            <QRCode value={record?.arrive_token || ""} />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {translate("ra.site.confirm_qr")}{" "}
            <span style={{ fontWeight: 700 }}>[{record?.confirm_token}]</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ position: "absolute" }}>
            <Button onClick={generateConfirmAction} variant="contained">
              {translate("ra.site.generate_qr")}
            </Button>
          </div>
          <div style={{ background: "white", padding: "64px" }}>
            <QRCode value={record?.confirm_token || ""} />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {translate("ra.site.complete_qr")}{" "}
            <span style={{ fontWeight: 700 }}>[{record?.complete_token}]</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ position: "absolute" }}>
            <Button onClick={generateCompleteAction} variant="contained">
              {translate("ra.site.generate_qr")}
            </Button>
          </div>
          <div style={{ background: "white", padding: "64px" }}>
            <QRCode value={record?.complete_token || ""} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const SiteShow = (props) => {
  const translate = useTranslate();
  const { record } = useShowController(props);
  return (
    <Show
      {...props}
      actions={<SiteShowActions />}
      // aside={<SiteShowAside record={record} />}
    >
      <TabbedShowLayout>
        <Tab label={translate("ra.site.title")}>
          <TextField source="name" label={translate("ra.site.name")} />
          <TextField source="address" label={translate("ra.site.address")} />
          <TextField
            source="districtName"
            label={translate("ra.site.district")}
          />
          <ReferenceField
            source="company_id"
            reference="client/companies"
            label={translate("ra.site.affiliated_company")}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="contact" label={translate("ra.site.contact")} />
          <TextField
            source="business_hour"
            label={translate("ra.site.business_hour")}
          />
          {/* <TextField source="lat" label="Latitude" />
            <TextField source="lng" label="Longtitude" /> */}
        </Tab>
        {/* <Tab label="通知">
            <p>Coming Soon</p>
          </Tab> */}
      </TabbedShowLayout>
    </Show>
  );
};

export default SiteShow;
