// in src/Menu.js
import * as React from "react";
import {
  DashboardMenuItem,
  Menu,
  MenuItemLink,
  usePermissions,
  useTranslate,
} from "react-admin";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import BusinessIcon from "@material-ui/icons/Business";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import AttachMoney from "@material-ui/icons/AttachMoney";

export const MyMenu = (props) => {
  const translate = useTranslate();
  const permission = usePermissions();
  const orderLocked = permission?.permissions?.is_verified === 0;
  return (
    <Menu {...props}>
      <DashboardMenuItem />
      <MenuItemLink
        disabled={orderLocked ? true : false}
        to="/client/orders"
        primaryText={translate(
          orderLocked ? "ra.page.order_locked" : "ra.page.order"
        )}
        leftIcon={<StarBorderIcon />}
      />
      <MenuItemLink
        to={`/client/companies/${localStorage.getItem("company_id")}/show`}
        primaryText={translate("ra.page.company_site")}
        leftIcon={<BusinessIcon />}
      />
      <MenuItemLink
        to={`/client/non-regular-order-item-requests`}
        primaryText={translate("ra.page.nro")}
        leftIcon={<WhatshotIcon />}
      />
      <MenuItemLink
        to={`/client/company-statements`}
        primaryText={translate("ra.page.companyStatement")}
        leftIcon={<AttachMoney />}
      />
    </Menu>
  );
};
