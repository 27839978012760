import React, { Fragment, cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  Filter,
  TextInput,
  useTranslate,
} from "react-admin";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";

import { getLocale } from "layout/MyAppBar";
import myAxios from "helpers/axios";

const CompanyStatementPanel = ({ id, record, resource }) => {
  return <div>123</div>;
};

const DownloadButton = ({ statementId }) => {
  const [showResultImage, setShowResultImage] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const downloadPdf = React.useCallback(() => {
    setDownloading(true);
    const params = new URLSearchParams();
    params.append("statement_id", statementId);
    if (showResultImage === true) {
      params.append("show_result_images", 1);
    }
    const reqUrl = myAxios.addApiUrl(
      `/client/pdf/statement?${params.toString()}`
    );
    try {
      myAxios
        .get(reqUrl, {
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
          setDownloading(false);
        })
        .catch((error) => {
          console.log(error);
          setDownloading(false);
        });
    } catch (error) {
      return { error };
    }
  }, [showResultImage, statementId]);
  return (
    <>
      <Button
        variant="ghost"
        startIcon={<CloudDownload color="primary" />}
        style={{ color: "#2e7d32", fontWeight: 700 }}
        color="primary"
        onClick={downloadPdf}
        disabled={downloading}
      >
        下載
      </Button>
      <FormControlLabel
        control={<Checkbox />}
        label={<Typography style={{ fontSize: 10 }}>附上完工圖片</Typography>}
        sx={{ fontSize: 10 }}
        onChange={() => setShowResultImage(!showResultImage)}
      />
    </>
  );
};

const CompanyStatementList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("ra.page.companyStatement")}
      bulkActionButtons={false}
      sort={{ field: "ref", order: "DESC" }}
      exporter={false}
    >
      <Datagrid rowClick={false} hasBulkActions={false}>
        <TextField
          sx={{ width: "200px" }}
          source="ref"
          label={translate("ra.companyStatement.ref")}
        />
        <FunctionField
          render={(record) => {
            const year = new Date(record.from_date).getFullYear();
            const month = new Date(record.from_date).getMonth();
            return `${year}年${month + 1}月`;
          }}
          label={translate("ra.companyStatement.date")}
        />
        <FunctionField
          render={(record) => {
            return <DownloadButton statementId={record.id} />;
          }}
          label=""
        />
      </Datagrid>
    </List>
  );
};

export default CompanyStatementList;
