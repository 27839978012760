export const DEV = process.env.NODE_ENV === "development";
const Config = {
  DEV,
  appName: "Three Masons - Express",
  publicWebPortal: "https://web.3-masons.com",
  publicHost: DEV ? "http://localhost:3000" : "https://express.3-masons.com",
  //apiHost: DEV ? 'https://api.3-masons.com/web' : 'https://api.3-masons.com/web',
  apiHost: DEV
    ? "http://localhost/three-masons-api/web"
    : "https://api.3-masons.com/web",
  staticImagesHost: DEV
    ? "http://localhost/resources/images"
    : "https://api.3-masons.com/resources/images",
  martixDistanceApi: "AIzaSyBu18zPILBSNut9VBchD6eK2760n2BIfK8",
  googleMapApiKey: "AIzaSyACx3QBKnlC2MwBZSHXya1Qm_Hj4YdBomo=",
};
export default Config;
