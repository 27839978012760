// in src/App.js
import * as React from "react";
import authProvider from "providers/authProvider";
import dataProvider from "providers/dataProvider";
import myTheme from "myTheme";
import MyLayout from "layout/MyLayout";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StoreIcon from "@material-ui/icons/Store";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Whatshot from "@material-ui/icons/Whatshot";

import {
  Admin,
  Resource,
  ListGuesser,
  useTranslate,
  usePermissions,
} from "react-admin";
import {
  SiteUserList,
  SiteUserShow,
  SiteUserEdit,
  SiteUserCreate,
} from "resource/company/siteUsers";
import {
  CompanyShow,
  CompanyEdit,
  CompanyCreate,
} from "resource/company/company";

import { WorkerSkillsetEdit } from "resource/worker/workerSkillset";
import OrderList from "resource/order/OrderList";
import OrderCreate from "resource/order/OrderCreate";
import OrderEdit from "resource/order/OrderEdit";
import OrderShow from "resource/order/OrderShow";

import Dashboard from "Dashboard";
import OrderItemEdit from "resource/orderItem/OrderItemEdit";
import OrderItemCreate from "resource/orderItem/OrderItemCreate";

import NroRequestList from "resource/nroRequest/NroRequestList";
import NroRequestEdit from "resource/nroRequest/NroRequestEdit";
import NroRequestShow from "resource/nroRequest/NroRequestShow";
import SiteRecipientsCreate from "resource/siteRecipients/SiteRecipientsCreate";
import i18nProvider from "providers/i18nProvider";

import { Route, BrowserRouter } from "react-router-dom";
import ChangePasswordPage from "components/Account/ChangePasswordPage";
import MyLoginPage from "layout/MyLoginPage";
import SiteShow from "resource/site/SiteShow";
import SiteCreate from "resource/site/SiteCreate";
import SiteEdit from "resource/site/SiteEdit";
import RequestResetPassword from "components/Account/ForgetPassword";
import ForgetPassword from "components/Account/ForgetPassword";
import ResetPassword from "components/Account/ResetPassword";
import AttachMoney from "@material-ui/icons/AttachMoney";
import CompanyStatementList from "resource/companyStatement/CompanyStatementList";

const App = () => {
  const translate = useTranslate();
  const permission = usePermissions();
  console.log(permission);
  return (
    <BrowserRouter>
      <Admin
        title="3Masons"
        layout={MyLayout}
        theme={myTheme}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        loginPage={MyLoginPage}
        customRoutes={[
          <Route
            noLayout
            exact
            path="/forget-password"
            component={ForgetPassword}
          />,
          <Route
            noLayout
            exact
            path="/reset-password"
            component={ResetPassword}
          />,
        ]}
      >
        <Resource
          name="client/site-users"
          /*list={SiteUserList}*/
          show={SiteUserShow}
          edit={SiteUserEdit}
          create={SiteUserCreate}
          icon={GroupWorkIcon}
          options={{
            label: "分店用戶",
          }}
        />
        <Resource
          name="client/companies"
          show={CompanyShow}
          icon={BusinessIcon}
          options={{
            label: "公司詳情",
          }}
        />
        <Resource
          name="client/sites"
          show={SiteShow}
          create={SiteCreate}
          edit={SiteEdit}
          icon={StoreIcon}
          options={{
            label: "分店",
          }}
        />
        <Resource
          name="code/service-items"
          icon={AssignmentIcon}
          options={{
            label: "維修項目",
          }}
        />
        <Resource
          name="client/orders"
          list={OrderList}
          show={OrderShow}
          create={OrderCreate}
          edit={OrderEdit}
          icon={StarBorderIcon}
          options={{
            label: translate("ra.page.order"),
          }}
        />
        <Resource
          name="client/non-regular-order-item-requests"
          list={NroRequestList}
          edit={NroRequestEdit}
          show={NroRequestShow}
          icon={Whatshot}
          options={{
            label: translate("ra.page.nro"),
          }}
        />
        <Resource
          name="client/company-statements"
          list={CompanyStatementList}
          icon={AttachMoney}
          options={{
            label: translate("ra.page.companyStatement"),
          }}
        />
        <Resource name="client/site-recipients" create={SiteRecipientsCreate} />
        <Resource
          name="admin/order-items"
          edit={OrderItemEdit}
          create={OrderItemCreate}
        />
        <Resource
          name="client/company-service-items"
          list={ListGuesser}
          options={{
            label: translate("ra.page.company_service_item"),
          }}
        />
        <Resource name="client/company-service-item-histories" />
        <Resource name="client/order/order-history" />
        <Resource name="admin/order/order-remark-images" />
        <Resource name="admin/worker-skillsets" edit={WorkerSkillsetEdit} />
        <Resource name="code/business-natures" />
        <Resource name="admin/worker-job/single-order-job" />
        <Route
          noLayout
          exact
          path="/change-password"
          component={ChangePasswordPage}
        />
      </Admin>
    </BrowserRouter>
  );
};
export default App;
